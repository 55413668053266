<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
<!--      <div class="table-page-search-wrapper">-->
<!--        <a-form layout="inline">-->
<!--          <a-row :gutter="48">-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="用户id" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" placeholder="请输入用户id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="规格id关联{zb_goods_sku.id}" prop="skuId">-->
<!--                <a-input v-model="queryParam.skuId" placeholder="请输入规格id关联{zb_goods_sku.id}" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
<!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
<!--              </span>-->
<!--            </a-col>-->
<!--          </a-row>-->
<!--        </a-form>-->
<!--      </div>-->
      <!-- 操作 -->
<!--      <div class="table-operations">-->
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:goods:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:goods:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:goods:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:goods:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
<!--      </div>-->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <Details ref="details" />
      <!-- 数据展示 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:goods:edit']" />-->
          <a @click="$refs.details.handleUpdate(record,undefined)">
            <a-icon type="edit" />View
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:goods:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['cigarette:goods:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGoods,listGoods, delGoods } from '@/api/cigarette/userGoods'
import CreateForm from './modules/CreateForm'
import Details from './modules/Details'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'UserGoods',
  components: {
    CreateForm,
    Details
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        skuId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Product ID',
          dataIndex: 'deviceId',
          align: 'center'
        },

        {
          title: 'SKU Models#/\n' +
            '\n' +
            'SKU Name',
          dataIndex: 'brandSkuModel',
          align: 'center',
          customRender(t,r) {
            return r.brandSkuModel+'/' + r.brandSkuName
          }
        },

        {
          title: 'Batch#',
          dataIndex: 'batchRecordId',
          align: 'center'
        },

        {
          title: 'Batch Name',
          dataIndex: 'batchName',
          align: 'center'
        },

        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          customRender(t,r) {
            if(r.status === 0) {
              return 'Unactivated';
            } else if(r.status == 1) {
              return 'Activated';
            } else if(r.status == 2) {
              return 'Disabled';
            }else if(r.status == 3) {
              return 'Abnormal';
            }else if(r.status == 4) {
              return 'Empty';
            }
          }
        },

        {
          title: 'Activated Time',
          dataIndex: 'activationTime',
          align: 'center'
        },
        // {
        //   title: '规格id关联{zb_goods_sku.id}',
        //   dataIndex: 'skuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.queryParam.userId = this.$route.query.userId || '';
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询新-用户产品记录列表 */
    getList () {
      this.loading = true
     pageGoods(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        skuId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGoods(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/user-goods/export', {
            ...that.queryParam
          }, `新-用户产品记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
